@import '~antd/dist/antd.css';

body {
  background-color: white;
  margin: 0;
  -webkit-font-smoothing: antialiased; }

* {
  outline: 0; }

/* Overrides for ant design components */
.ant-btn {
  min-width: 102px; }

.ant-btn-icon-only {
  min-width: 32px;
}
.ant-btn-icon-only.ant-btn-sm:not(.ant-btn-circle) {
  border-radius: 4px !important; 
}
.ant-btn-ghost {
  border: none !important;
  box-shadow: none !important; }

.ant-btn-primary span {
  font-weight: bold;
}
.ant-btn-sm {
  min-width: 0 !important;
}
.ant-btn-sm:not(.ant-btn-icon-only) {
  padding: 0 14px !important;
}
.ant-tag {
  font-weight: bold; }

.ant-card-head {
  border-bottom: none !important;
  margin-bottom: -36px !important; }
  .ant-card-head-title {
    padding-bottom: 0 !important;
    font-size: 16px;
    color: #848B94; }

.ant-select-lg .ant-select-selector {
  height: 48px; }

.ant-steps-item-active .ant-steps-item-icon {
  background: #00a2ff !important;
  border-color: #00a2ff !important;
  color: #fff !important; }

.ant-steps-item-finish .ant-steps-item-icon {
  background: #00cc83 !important;
  border-color: #00cc83 !important; }

.ant-steps-item-finish .ant-steps-icon {
  color: #fff !important; }

.ant-steps-item-title::after {
  background-color: #d7dadf !important; }

.ant-steps-item-title .ant-typography {
  color: #a1a7af !important; }

.ant-steps-item-active .ant-steps-item-title .ant-typography {
  color: #848b94 !important;
  font-weight: bold !important; }

.ant-modal-header {
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
  border-radius: 8px 8px 0 0 !important; }

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 0px !important;
  padding: 0 24px 16px !important; }
  .ant-modal-footer .tertiary-button {
    margin-right: auto; }

.ant-modal-content {
  border-radius: 8px !important; }

.ant-progress-inner {
  border-radius: 0px !important; }

.ant-checkbox-inner {
  border-radius: 2px !important; }

.ant-table-thead > tr > th {
  font-weight: bold !important; }

.ant-form .ant-form-item:last-child {
  margin-bottom: 0; }

/* End overrides for ant design components */
.csv-label {
  height: 0;
  opacity: 0; }

.passport-month-selection {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px;
  margin-top: auto;
  margin-bottom: auto; }

.page-link {
  position: relative;
  display: block;
  padding: 0.2rem 0.75rem;
  line-height: 1.25;
  color: #676c74;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
  height: 20px;
  font-size: 10px; }

.page-link:hover {
  z-index: 2;
  color: black;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #676c74;
  border-color: #e4e7eb; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.csv-reader-container {
  display: flex;
  flex-direction: column;
  margin-top: 13px;
  color: #676c74;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px; }
